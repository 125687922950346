<template>
<v-row style="margin:auto">
    <!-- <div class="col-md-12 col-sm-12"> -->
    <v-col cols="12" md="12" style="margin-left: 0px; padding: unset; margin-top: 0px;">
        <s-toolbar :label="'Registro de Historial de Cobranza'" save @save="save()" dark close @close="close()" class="" />
    </v-col>
    <v-card dense>
        <v-row style="margin: auto;">
            <v-col cols="12" lg="12" md="12">
                <v-card>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="6" lg="6" class="pb-0 pt-4">
                                <s-select-definition :def="1136" label="Modo" v-model="items.TypeMode"></s-select-definition>
                            </v-col>
                            <v-col cols="6" lg="6" class="pb-0 pt-4">
                                <s-select-definition :def="1137" label="Gestiòn" v-model="items.TypeGestion"></s-select-definition>
                            </v-col>
                            <v-col cols="6" lg="6" class="pb-0 pt-4">
                                <s-select-definition :def="1215" label="Modalidad de Trabajo" v-model="items.TypeModalidad"></s-select-definition>
                            </v-col>
                            <v-col cols="6" lg="6" class="pb-0 pt-4">
                                <s-select-definition :def="1216" label="Origen de Ingresos" v-model="items.TypeOrigen"></s-select-definition>
                            </v-col>
                            <v-col cols="12" lg="12" class="pb-0 pt-4">
                                <s-textarea label="Descripciòn" v-model="items.GesDescription"></s-textarea>
                            </v-col>

                            <v-col cols="6" lg="3" md="3" class="pb-0 pt-4">
                                <b>
                                    <v-text-field label="Doc. Cliente" v-model="items.DocCustomer" readonly></v-text-field>
                                </b>
                            </v-col>

                            <v-col cols="6" lg="3" md="3" class="pb-0 pt-4">
                                <b>
                                    <v-text-field label="Nombre del cliente" v-model="items.CustomerName" readonly></v-text-field>
                                </b>
                            </v-col>
                            <v-col cols="6" lg="3" md="3" class="pb-0 pt-4">
                                <b>
                                    <v-text-field label="Apellidos del cliente" v-model="items.CustomerPaternalSurname" readonly></v-text-field>
                                </b>
                            </v-col>

                            <v-col cols="6" lg="3" md="3" class="pb-0 pt-4">
                                <b>
                                    <!--  <v-text-field label="Fecha Compromiso" readonly></v-text-field> -->
                                    <s-date label="Fecha Compromiso" v-model="items.GetDateCommitment" clearable></s-date>
                                </b>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" class="pb-0 pt-0">
                                <b>
                                    <v-text-field label="Direcciòn del cliente" v-model="items.CustomerAddress" readonly></v-text-field>
                                </b>
                            </v-col>

                            <v-col cols="4" lg="3" md="3" class="pb-0 pt-0">
                                <b>
                                    <v-text-field label="Tel. Cliente" v-model="items.CustomerTel" readonly></v-text-field>
                                </b>
                            </v-col>

                            <v-col cols="4" lg="3" md="3" class="pb-0 pt-0">
                                <b>
                                    <v-text-field label="Cel. Cliente" v-model="items.CustomerCel" readonly></v-text-field>
                                </b>
                            </v-col>

                            <v-col cols="3" lg="3" md="3" class="pb-0 pt-0">
                                <b>
                                    <v-text-field label="Codigo Venta" v-model="items.CodSales" readonly></v-text-field>
                                </b>
                            </v-col>

                            <v-col cols="4" lg="3" md="3" class="pb-0 pt-0">
                                <b>
                                    <v-text-field label="Lote Cliente" v-model="items.NroLot" readonly></v-text-field>
                                </b>
                            </v-col>

                            <v-col cols="8" lg="6" md="6" class="pb-0 pt-0">
                                <b>
                                    <v-text-field label="Proyecto" v-model="items.ProjectName" readonly></v-text-field>
                                </b>
                            </v-col>

                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>

    </v-card>

    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error al grabar registro <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- </div> -->
</v-row>
</template>

<script>
import _sCollection from "@/services/Collection/CobSearcCustomerService.js"

export default {
    props: {
        items: null
    },
    data: () => ({
        processing: false,
        messageProcessing: "",
    }),

    created() {
        
    },

    methods: {
        clear() {
            this.items.GetDateCommitment = null
            this.items.GesDescription = ""
            this.items.TypeMode = 1
            this.items.TypeGestion = 1
            this.items.TypeModalidad = 1
            this.items.TypeOrigen = 1
        },
        close() {
            this.clear()
            this.$emit("close");
        },

        save() {

            if (this.items.GesDescription == null || this.items.GesDescription.length == 0 || this.items.GesDescription == "") {
                this.$fun.alert("Ingrese una Observación", "warning")
                return
            }

            if (this.items.DocCustomer == null || this.items.DocCustomer.length == 0 || this.items.DocCustomer == "") {
                this.$fun.alert("No se ha cargado Doc. Identidad Cliente", "warning")
                return
            }

            if (this.items.CodSales == null || this.items.CodSales.length == 0 || this.items.CodSales == "") {
                this.$fun.alert("No se ha cargado codigo de venta", "warning")
                return
            }

            if (this.items.GetDateCommitment < this.$fun.getDate()) {
                this.$fun.alert("Fecha de compromiso no puede ser menor a fecha actual.", "warning")
                return
            }

            this.items.CodSales = this.items.CodSales.trim();
            this.items.GetStatus = 1
            this.items.UsrCreateID = localStorage.getItem("UsrName")
            this.messageProcessing = ""

            this.$fun.alert("¿Seguro de grabar registro?", "question").then(x => {

                if (x.value) {
                    this.processing = true;
                    _sCollection.saveGestion(this.items, this.$fun.getUserID()).then(resp => {

                            if (resp.status == 200) {
                                this.processing = false;
                                this.$fun.alert("Grabado Correctamente", "success")
                                //this.clear()
                                this.close();

                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            })

        }
    }

};
</script>

<style>
.tran {
    opacity: 0.1em !important;
}
</style>
