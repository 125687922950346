<template>
<div>
    <v-container>
        <v-row>

            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Gestiòn de Cobranzas" class="tran"></s-toolbar>
                <v-card outlined>

                    <v-row style="margin:auto">
                        <v-col cols="12">
                            <v-card class="mx-auto my-1" width="100%">

                                <v-alert v-model="alert" style="border-radius: 10px 10px 10px 10px; overflow: auto; z-index: 9999999; width: 100%; right: 0px; top: 5%;" border="right" color="error" dark dense dismissible shaped close-icon="fas fa-times-circle">
                                    <!-- v-card-text>No Existe ningún Cliente con ese Nro de Documento</v-card-text> -->
                                    <span style="font-family: Calibri; font-size: 14px">No Existe ningún Cliente con ese Nro de Documento</span>
                                </v-alert>
                            </v-card>
                            <v-card outlined class="mx-auto my-1 pb-3" width="504">
                                <v-row justify="center" style="margin-top: 4px; margin-left: 10px;">
                                    <v-col cols="8" lg="7" md="7">
                                        <s-text ref="txtDocIdentidad" v-model="txtDocIdentidad" label="Ingrese Doc. Identidad" @keyupEnter="btnSearch()"></s-text>
                                    </v-col>
                                    <v-col cols="2" lg="3" md="3">
                                        <v-btn elevation="0" class="mt-4" small color="success" @click="btnSearch()">
                                            Buscar
                                        </v-btn>
                                    </v-col>

                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row style="margin:auto">
                        <v-col cols="12">
                            <v-card outlined class="mx-auto my-1 pb-2" width="100%" v-for="item in items" :key="item.CodSales">
                                <v-row justify="center" style="margin-top: 4px; margin-left: 10px;">
                                    <v-col cols="6" lg="2" md="2" class="pb-0 pt-4">
                                        <b>
                                            <v-text-field label="Nro. Documento" v-model="item.DocCustomer" readonly></v-text-field>
                                        </b>
                                    </v-col>
                                    <v-col cols="6" lg="3" md="3" class="pb-0 pt-4">
                                        <b>
                                            <v-text-field label="Cliente" v-model="item.CustomerName" readonly></v-text-field>
                                        </b>
                                    </v-col>
                                    <v-col cols="4" lg="2" md="2" class="pb-0 pt-4">
                                        <b>
                                            <v-text-field label="Nro. Inmueble" v-model="item.NroLot" readonly></v-text-field>
                                        </b>
                                    </v-col>
                                    <v-col cols="6" lg="4" md="4" class="pb-0 pt-4">
                                        <b>
                                            <v-text-field label="Proyecto" v-model="item.ProjectName" readonly></v-text-field>
                                        </b>
                                    </v-col>

                                    <v-col cols="2" lg="1" md="1" style="margin-top: 6px">
                                        <v-btn :disabled="items.length == 0 ? true: false" @click="add(item)" :color="'#5175AF'" dark fab small><i style="font-size: 20px" class="fas fa-plus-circle"></i></v-btn>
                                    </v-col>

                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-card outlined>

                            
                            <v-data-table class="elevation-0 scroll table table-hover table-condensed"  height="300px" disable-sort disable-filtering dense :headers="headers" :items="itemsGestion">
                                <template v-slot:item.Delete="{item}">
                                    <v-btn v-if="item.CobUserName == UsrName || $fun.isAdmin()" x-small color="error" fab width="25px" height="25px" @click="deleteItem(item)"><i class="fas fa-ban"></i></v-btn>

                                </template>
                            </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="processing" v-if="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere.
                    <v-progress-linear indeterminate color="white" class="mb-0 mt-3"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="openDialog" v-if="openDialog" scrollable persistent transition="dialog-bottom-transition">
            <cob-manager-customer-add @close="close()" :items="itemOne">

            </cob-manager-customer-add>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import _sCollection from "@/services/Collection/CobSearcCustomerService.js"

import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import CobManagerCustomerAdd from "@/views/Collection/CobManagerCustomerAdd.vue";

export default {

    components: {
        CobManagerCustomerAdd
    },
    data() {
        return {
            processing: false,
            messageProcessing: "",
            itemOne: {

            },
            alert: false,

            items: [],
            itemsGestion: [],
            headers: [{
                    text: "Del",
                    value: "Delete",
                    width: 50
                }, {
                    text: "Fecha",
                    value: "CobDateRegister",
                    width: 120
                },
                {
                    text: "Descripciòn",
                    value: "CobDescription",
                    width: 400

                },
                {
                    text: "Usuario",
                    value: "CobUserName",
                    width: 100
                },
                {
                    text: "Codigo Venta",
                    value: "CobCodSales",
                    width: 100
                },
                {
                    text: "Lote",
                    value: "CobNroLot",
                    width: 80
                },
                {
                    text: "Proyecto",
                    value: "CobProjectName",
                    width: 250
                },
                {
                    text: "Modalidad T.",
                    value: "TypeModalidadName",
                    width: 100
                },
                {
                    text: "Origen I.",
                    value: "TypeOrigenName",
                    width: 100
                },
                {
                    text: "Modo",
                    value: "TypeModName",
                    width: 100
                },
                {
                    text: "Gestiòn",
                    value: "TypeGestionName",
                    width: 200
                },
                {
                    text: "Fec. Compromiso",
                    value: "CobDateCommitment",
                    width: 120
                },

            ],
            openDialog: false,
            txtDocIdentidad: "",
            UsrName: localStorage.getItem("UsrName").toUpperCase()
        };
        
    },

    created(){
    },

    methods: {

        add(item) {

            this.openDialog = true
            this.itemOne = item
        },

        close() {
            this.openDialog = false
            this.btnSearchGestion()
        },

        btnSearch() {
            this.itemsGestion = []
            this.itemOne = {}
            var obj = {
                QryParamsArray: "NumberDocument",
                QryParamsArrayValue: this.txtDocIdentidad, //this.DateBegin + "," + this.DateEnd + "," + this.number;
                QryNameProcedure: "CobSearchCustomer"
            }
            this.processing = true;
            _sQryConfigurationService
                .querygeneral(obj, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.items = resp.data

                      
                            if (this.items.length > 0) {
                                this.btnSearchGestion()
                                this.$refs.txtDocIdentidad.focus()
                                this.alert = false
                            } else {
                                this.alert = true
                                this.$refs.txtDocIdentidad.focus()
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );

        },

        btnSearchGestion() {
            var obj = {
                QryParamsArray: "NumberDocument",
                QryParamsArrayValue: this.txtDocIdentidad, //this.DateBegin + "," + this.DateEnd + "," + this.number;
                QryNameProcedure: "CobSearchCustomerGestion"
            }
            _sQryConfigurationService
                .querygeneral(obj, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {

                            if (resp.data.length > 0) {
                                resp.data.forEach(element => {
                                    element.CobDateRegister = element.CobDateRegister == null ? "" : this.$moment(element.CobDateRegister).format(this.$const.FormatDateTimeDB);
                                    element.CobDateCommitment = element.CobDateCommitment == null ? "" : this.$moment(element.CobDateCommitment).format(this.$const.FormatDateDB);
                                });
                            }

                            this.itemsGestion = resp.data
                        }
                    }
                );
        },

        deleteItem(item) {

            item.IdNew = 1

          
            this.$fun.alert("¿Seguro de eliminar registro?", "question").then(x => {

                if (x.value) {
                    this.messageProcessing = ""
                    this.processing = true;
                    _sCollection.saveGestion(item, this.$fun.getUserID()).then(resp => {

                            if (resp.status == 200) {
                                this.processing = false;
                                this.$fun.alert("Eliminado Correctamente", "success")
                                this.btnSearchGestion()

                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            })
        },

        save() {

        }
    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
